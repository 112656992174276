<script setup lang="ts">
import { emitter } from '~/events';
import MiPixLogo from '~/assets/logo.json'

import UserDropdown from './components/UserDropdown.vue'
import LangDropdown from './components/LangDropdown.vue'

const authStore = useAuthStore()
const router = useRouter();
const route = useRoute();
const computedClasses = ref('')
const mounted = useMounted()

onMounted(() => computedClasses.value = authStore.isLoggedIn ? 'hidden' : '')

const amount = computed(() => $numberFormat(authStore.user.wallet.balance_available, 2, ',', '.'))

watch(() => authStore.isLoggedIn, () => computedClasses.value = authStore.isLoggedIn ? 'hidden' : '')
const goToLogin = () => router.push({ name: 'auth-login', query: { redirect: encodeURIComponent(window.location.pathname) }})
</script>
<template>
  <div>
    <nav class="header after-gradient">
      <div class="flex xl:hidden items-center">
        <button type="button" class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg xl:hidden" @click.prevent="emitter.emit('toggle-sidebar')">
          <span class="sr-only">Open sidebar</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" fill-rule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z">
            </path>
          </svg>
        </button>
      </div>
      <div class="w-full flex justify-start">
        <div>
          <nuxt-link to="/">
            <Vue3Lottie :animationData="MiPixLogo" class="min-w-[8rem] max-w-[10rem] md:max-w-[12rem]" />
          </nuxt-link>
        </div>
        <div class="ml-10 hidden lg:flex">
          <nuxt-link :to="{ name: 'index' }" class="menu-item"
            :class="{ active: route.name == 'index' }">
            Cassino
          </nuxt-link>

          <nuxt-link :to="{ name: 'sports' }" class="menu-item"
            :class="{ active: route.name == 'sports' }">
            Apostas Esportivas
          </nuxt-link>
        </div>
      </div>
      <div class="w-full flex justify-end items-center gap-3 pr-2">
        <div class="user-info">
          <nuxt-link :to="{ name: 'promotions' }" class="b">Bônus e Promoções</nuxt-link>
          <nuxt-link :to="{ name: 'account-refers' }"><span class="text-primary-300 font-medium">$$</span> Indique e
            ganhe!</nuxt-link>

          <button type="button"
            @click="router.push({ name: 'promotions-promotion', params:{ promotion: 'cashback-semanal' } })"
            class="focus:outline-none text-secondary-950 bg-white hover:bg-gray-300 focus:ring-1 focus:ring-green-300 font-medium rounded-lg text-xs px-2.5 py-1.5">
            Cashback
          </button>
        </div>
        <button @click="goToLogin" type="button" :class="computedClasses"
          class="auth-button">
          Entrar | Cadastrar
        </button>
        <div class="flex justify-between items-center bg-opacity w-28 py-1 px-2 rounded-md select-none" v-if="mounted && authStore.isLoggedIn">
          <button @click="() => authStore.toogleDataVisible()" class="pe-2"
          >
            <Icon v-if="authStore.visibleData" name="ph:eye" class="text-gray-400" size="16" />
            <Icon v-else name="ph:eye-slash" class="text-gray-400" size="16" />
          </button>
          <div class="font-medium overflow-hidden text-nowrap" v-if="authStore.visibleData">R$ {{ amount }}</div>
          <div class="font-medium overflow-hidden text-nowrap" v-else>R$ <span v-for="_ in amount.length">*</span></div>
        </div>
        <user-dropdown v-if="authStore.isLoggedIn" />
        <lang-dropdown v-else />
      </div>
    </nav>
  </div>
</template>

<style scoped>
@-webkit-keyframes mp-pulse{10%,90%{-webkit-transform:translate3d(-1px,0,0);-ms-transform:translate3d(-1px,0,0);transform:translate3d(-1px,0,0);}0%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);}70%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);box-shadow:0 0 0 15px rgba(90,153,212,0);}100%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);box-shadow:0 0 0 0 rgba(90,153,212,0);}}/*!sc*/

@keyframes mp-pulse{10%,90%{-webkit-transform:translate3d(-1px,0,0);-ms-transform:translate3d(-1px,0,0);transform:translate3d(-1px,0,0);}0%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);}70%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);box-shadow:0 0 0 15px rgba(90,153,212,0);}100%{-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1);box-shadow:0 0 0 0 rgba(90,153,212,0);}}

.header {
  @apply absolute lg:fixed top-0 left-0 w-full flex justify-between items-center bg-secondary-950 h-16 px-2 md:px-6 z-[99]
}

.top-nav {
  @apply list-none items-center ml-8
}

.auth-button {
  @apply focus:outline-none text-secondary-950 bg-primary-300 hover:bg-primary-400 focus:ring-1 focus:ring-green-300 font-medium rounded-lg text-xs px-2 py-1.5 md:px-2.5 md:py-1.5 shadow-primary-300;
    transition: background-color 0.2s ease 0s;
    box-shadow: #4CFFC3 0px 0px 0px 0px;
    animation: 1.5s ease 0s infinite normal none running mp-pulse;
}

.user-info {
  @apply hidden lg:flex items-center justify-between text-white text-sm gap-3;
}
.user-info a {
  @apply ml-2
}

.user-info a.b::after {
  content: '';
  position: absolute;
  display: 'block';
  font-size: 10px;
  width: 1px;
  height: 12px;
  margin-top: 5px;
  margin-left: 10px;
  background-color: #8e8f8e;
}

a.menu-item,div.menu-item {
  @apply flex items-center p-1 relative z-[1] text-primary-300 text-sm font-light ml-3
}

a.menu-item.active {
  @apply font-semibold
}

a.menu-item.active, a.menu-item:hover {
  @apply text-white border-b border-primary-300 border-solid
}
</style>