<script setup lang="ts">
import { emitter } from '~/events';
const whatsapp = ref<string>('553135001363');
const authStore = useAuthStore()
const appStore = useAppStore()

const user = useUser()

const logout = async () => {
  try {
    appStore.setLoading(true)
    await authStore.logout()
  } catch (error) {
    console.error(error)
  } finally {
    appStore.setLoading(false)
  }
}

const menu = ref()
const items = ref([
  {
    separator: true
  },
  {
    items: [
      {
        label: 'Saques e Depositos',
        wallet: true,
        icon: 'tdesign:money',
        to: undefined
      },
      {
        label: 'Minha Carteira',
        icon: 'tdesign:wallet',
        to: '/account/overview'
      },
      {
        label: 'Indicações',
        icon: 'tdesign:usergroup-add',
        to: '/account/refers'
      },
      {
        label: 'App Download',
        icon: 'tdesign:download',
        to: undefined,
        hidden: true
      },
      {
        label: 'Promoções',
        icon: 'bi:megaphone',
        to: '/promotions'
      },
      {
        label: 'Suporte ao vivo',
        icon: 'tdesign:logo-wechat-stroke',
        href: `https://api.whatsapp.com/send?phone=${whatsapp.value}&text=Ol%C3%A1%2C%20MiPix%21`,
        to: undefined
      },
      {
        separator: true
      },
    ]
  },
  {
    items: [
      {
        label: 'Desconectar',
        icon: 'tdesign:logout',
        logout: true,
        to: undefined
      },
    ]
  }
])

const createOverlay = () => {
  const overlay = document.createElement('div');
  overlay.id = 'overlay_menu';
  overlay.classList.add('w-full', 'fixed', 'top-0', 'left-0', 'right-0', 'bottom-0', 'z-50', 'bg-black', 'bg-opacity-40', 'backdrop-blur');

  // Adicionar a div overlay ao corpo do documento
  document.body.appendChild(overlay);
}

const removeOverlay = () => {
  try {
    document.getElementById("overlay_menu")!.remove();
  } catch (error) { }
}

watch(() => menu.value?.overlayVisible, (val) => {
  if (menu.value && val) {
    try {
      createOverlay()
    } catch (error) { }

    setTimeout(() => {
      try {
        document.getElementById(menu.value.id)!.style.position = 'fixed';
        document.getElementById(menu.value.id)!.style.top = '46px';
      } catch (error) { }
    }, 100)
  } else {
    try { removeOverlay() } catch (error) { }
  }
})

const toggle = (event: any) => menu.value.toggle(event)
</script>

<template>
  <div class="flex items-center relative">
    <button type="button" @click="toggle" class="bg-secondary-950 rounded-full border border-primary-300 w-7 h-7"
      aria-haspopup="true" aria-controls="user_menu">
      <span class="sr-only">Open user menu</span>
      <img v-if="authStore.isLoggedIn" class="w-full rounded-full" :src="authStore.user.avatar" alt="user photo" />
      <div class="flex justify-center items-center absolute w-3.5 h-3.5 rounded-full bg-primary-300 border border-secondary-940 -bottom-0.5 -right-1">
        <Icon name="tdesign:chevron-down-s" size="22" class="text-secondary-950"/>
      </div>
    </button>
    <Menu ref="menu" id="user_menu" :model="items" class="w-60 !mt-2" :popup="true">
      <template #start>
        <button v-ripple
          class="relative overflow-hidden w-full border-0 bg-transparent flex items-center p-2 pl-4 hover:bg-surface-100 rounded-none cursor-pointer transition-colors duration-200">
          <img class="w-8 h-8 mr-2 rounded-full" :src="user.avatar" alt="user photo" />
          <span class="inline-flex flex-col items-start">
            <span class="font-bold">{{ user.name }}</span>
            <span class="text-sm">{{ user.email }}</span>
          </span>
        </button>
      </template>
      <template #submenuheader>
        <div class="w-full relative after-gradient"></div>
      </template>
      <template #item="{ item, props }">
        <a v-ripple class="flex items-center gap-x-4 hover:!text-white" v-bind="props.action" v-if="!item.hidden">
          <Icon :name="item.icon" v-if="item.icon" class="text-muted-color" />
          <nuxt-link :to="item.to" v-if="item.to">{{ item.label }}</nuxt-link>
          <a :href="item.href" v-else-if="item.href" target="_blank">{{ item.label }}</a>
          <span v-else-if="item.wallet" @click="emitter.emit('cashbox-modal', { type: 'deposit', action: 'open' })">{{
            item.label }}</span>
          <span v-else-if="item.logout" @click="logout()">{{ item.label }}</span>
          <span v-else>{{ item.label }}</span>
        </a>
      </template>
    </Menu>
  </div>
</template>
