<script setup lang="ts">
const { locale, locales } = useI18n()
const appStore = useAppStore()

const currentLang = computed(() => locales.value.find((lang) => lang.code === locale.value))
</script>
<template>
  <button
    type="button"
    id="select-lang"
    data-dropdown-toggle="dropdown-select-lang"
  >
    <Icon :name="`flag:${currentLang?.iso}-1x1`" class="rounded-full w-7 h-7 lg:w-6 lg:h-6 border border-white" />
  </button>

  <!-- Dropdown menu -->
  <div
    id="dropdown-select-lang"
    class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
  >
    <ul class="py-2 pl-2 text-sm text-gray-700" aria-labelledby="select-lang">
      <li v-for="lang in locales" :key="lang.code" :class="{'bg-gray-200 !rounded-lg': locale === lang.code}">
        <a
          @click.prevent.stop="appStore.setLang(lang.code)"
          class="hover:bg-gray-100 flex items-center justify-start gap-2 p-2 mr-2 rounded-lg cursor-pointer"
        >
          <Icon :name="`flag:${lang.iso}-1x1`" class="rounded-full w-6 h-6" />
          {{ lang.name }}
        </a>
      </li>
    </ul>
  </div>
</template>
